import React from "react";
import { BsLinkedin } from "react-icons/bs";

const SocialMedia = () => {
  const openLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/in/alexandru-kelemen-577755205/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="app__social">
      <div>
        <BsLinkedin onClick={openLinkedIn} style={{ cursor: "pointer" }} />
      </div>
    </div>
  );
};

export default SocialMedia;
